import * as React from 'react';
import coverImage from '../../assets/images/gallery/34349_Ai_Nguyen_bunader_jan_2018_0056.jpg';
import BunadInfoList from './components/BunadInfoList';

export class Bunader extends React.Component {
    bgImg: React.RefObject<HTMLImageElement>;

    constructor(props: React.ReactPropTypes) {
        super(props);
        this.bgImg = React.createRef();
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const img = new Image();
        img.src = coverImage;
        img.onload = () => {
            const imgContainer = this.bgImg.current;
            if (imgContainer !== null) {
                imgContainer.style.backgroundImage = `url('${img.src}')`;
                imgContainer.classList.add("u-fade-in");
            }

        }
    }

    render() {
        return (
            <div className="bunader">
                <div className="home-menu__container"></div>
                <div className="bunader__image-wrapper">
                    <div className="bunader__image" ref={this.bgImg} />
                </div>

                <div>
                    <h1>Bunader</h1>
                    <p>Mer bilder kommer senere.</p>
                    <BunadInfoList />
                </div>
            </div>
        )
    }

}

export default Bunader;