import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import Bunader from './pages/bunader/Bunader';

import './custom.css';
import './main.scss';
import About from './pages/about/About';

export default () => (
    <Layout>
        <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/bunader' component={Bunader} />
            <Route exact path='/about' component={About} />
        </Switch>
    </Layout>
);
