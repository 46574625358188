import * as React from 'react';

import herrebunad from '../../../assets/images/gallery/men/herre1.jpg';
import kvinnebunad1 from '../../../assets/images/gallery/women/woman1.jpg';
import kvinnebunad2 from '../../../assets/images/gallery/women/woman2.jpg';
import kvinnebunad3 from '../../../assets/images/gallery/women/woman3.jpg';
import { Link } from 'react-router-dom';


class BunadInfoList extends React.Component {
    bunadInfoList = [
        {
            image: herrebunad,
            title: 'Romeriksbunad',
            link: ''
        },
        {
            image: kvinnebunad1,
            title: 'Romeriksbunad',
            link: ''
        },
        {
            image: kvinnebunad2,
            title: 'Nordlandsbunad',
            link: ''
        },
        {
            image: kvinnebunad3,
            title: 'Oslobunad',
            link: ''
        }
    ];

    render() {
        return (
            <div className="bunadlist">
                <div className="bunadlist__wrapper">
                    {
                        this.bunadInfoList.map((item, index) => {
                            return (
                                <div key={index} className="bunadlist__item">
                                    <div className="bunadlist__item__wrapper">
                                        <img src={item.image} alt="bunad bilde" />
                                    </div>
                                    {<h2>{item.title}</h2>}
                                </div>
                            )
                        })
                    }
                </div>
            </div>)
    }
}

export default BunadInfoList;