import * as React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as BunadtilvirkeLogo } from '../assets/images/footer-bilde.svg';



const Footer = () => (
    <footer className="footer">
        <div className="footer__container">

            <header className="footer__info">
                <h3>ROMERIKE BUNADTILVIRKER</h3>
                <div className="footer__content">
                    <div className="footer__bunadinfo">
                        <div className="footer__bunadinfo__items">
                            <Link className={`footer-link`} to='/bunader'>Bunader</Link>
                            <Link className={`footer-link`} to='/about'>Om oss</Link>
                            <Link className={`footer-link`} to='/contact'>Kontakt</Link>
                        </div>
                    </div>

                    <div className="footer__bunadinfo opening-hours">
                        <h3>Åpningstider</h3>
                        <div className="footer__bunadinfo__items">
                            <span>Mandager: 11:00 - 16:00</span>
                            <span>Tirsdager og Onsdager: 11:00 - 18:00</span>
                            <span>Torsdager: 11:00 - 17:00</span>
                            <span>Fredager: 11:00 - 18:00</span>
                            <span>Lørdager: etter avtale</span>
                            <span>Søndager: stengt</span>
                        </div>
                    </div>

                    <div className="footer__bunadinfo contact-info">
                        <h3>Kontaktinformasjon</h3>
                        <div className="footer__bunadinfo__items">
                            <span>ai@romerikebunadtilvirker.no</span>
                            <span>Torvet 5 2000 Lillestrøm</span>
                            <span>+47 950 13 187</span>
                        </div>
                    </div>
                </div>
            </header>

            <BunadtilvirkeLogo className="footer__bunadlogo" />
        </div>
    </footer>
);

export default Footer;
