
import * as React from 'react';
import { Link } from 'react-router-dom';

import bunadtilvirkerPicture from '../../assets/images/general/28NYHBunad_01-07-773A2643-min.jpg';

const ParagraphInfo = () => {
    return (
        <React.Fragment>
            <p>
                For å kunne levere de staselige bunadene til kundene våre, bruker vi
                norske skreddermetoder i arbeidet med bunadene. Fra august 2017 styres
                Romerike Bunadtilvirker AS av bunadtilvirker Ai Thi Nguyen som har lang
                erfaring og høy faglig kompetanse.
                </p>

            <p>
                Bunadtilvirkeren har sju års utdanning fra søm og skredderfag i Norge og
                har tatt fire svennebrev i Oslo. Hun har svennebrev som herreskredder,
                dameskredder og bunadtilvirker og svennebrev i kjole-og draktsyerfaget.
                </p>

            <p>
                Ai Thi Nguyen leverte bunadskjorter for Heimen Husfliden i Oslo fra
                1994-1997, og var fast ansatt hos Heimen Husfliden som herreskredder,
                dameskredder bunadtilvirker og tilskjærer fra 1997-2017. Hun er også
                medlem av prøvenemnda i kjole-og draktsyerfaget og bunadtilvirkerfaget
                for Akershus Fylkeskommune.
                </p>
        </React.Fragment>

    );
}

class About extends React.PureComponent<{}, { isOpen: boolean, screenWidth: number }> {
    public state = {
        isOpen: false,
        screenWidth: window.innerWidth
    };
    aboutUsImage: React.RefObject<HTMLImageElement>;

    constructor(props: React.ReactPropTypes) {
        super(props);
        this.aboutUsImage = React.createRef();
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const img = new Image();
        img.src = bunadtilvirkerPicture;
        img.onload = () => {
            const imgContainer = this.aboutUsImage.current;
            if (imgContainer !== null) {
                imgContainer.style.backgroundImage = `url('${img.src}')`;
                imgContainer.classList.add("u-fade-in");
            }

        }
    }
    render() {
        return (
            <div className="about">

                <div className="about__intro">
                    <h1>Om oss</h1>

                    <p>
                        Romerike Bunadtilvirker AS ble etablert i 2016. Butikken ligger sentralt
                        i Lillestrøm. Romerike Bunadtilvirker AS fokuserer på norske
                        bunadstradisjoner og arbeid av høyeste kvalitet.
                </p>
                </div>
                <div className="about__container">

                    <div className="about__image" ref={this.aboutUsImage} />


                    <div className="about__content">
                        <div className="about__info">
                            <h2>Ai Thi Nguyen</h2>
                            <h3>Bunadtilvirker</h3>
                            <ParagraphInfo />

                        </div>
                        <div className="about__button-container">
                            <Link className={`btn btn-primary`} to='/contact' >Ta kontakt</Link>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default About;
