import * as React from 'react';
import { connect } from 'react-redux';
import ContactForm from './components/ContactForm';
import Map from './components/Map';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

class Contact extends React.PureComponent<{}, { show: boolean, disableButton: boolean, screenWidth: number }> {
  public state = {
    show: false,
    disableButton: false,
    screenWidth: window.innerWidth
  };

  updateWindowDimensions() {
    this.setState({ screenWidth: window.innerWidth });
  }

  constructor(props: any) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  onSubmit(values: any) {
    var data  = values;
    var url = 'https://bunadtilvirkefunctionapp20200819072924.azurewebsites.net/api/SendEmail';
    var xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    // xhr.withCredentials = true;
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4 && xhr.status === 200) {
        }
    };
    var json = JSON.stringify(values);
    xhr.send(json);

    this.setState({
      show: true,
      disableButton: true
    });
  }

  toggle() {
    this.setState({
      show: false
    })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  render() {
    return (
      <div className="contact">
        <h1>Kontakt</h1>
        <div className="contact__info-container">
          <div className="contact__info_item">
            <p className="info-paragraph">
              Har du noen spørsmal eller har du lyst til å bestille prøvetime, kan du sende epost eller ringe.
          </p>

            <React.Fragment>
              {
                this.state.screenWidth < 768 &&
                (<div className="contact__info_item item3">
                  <span>
                    Åpningstider:
                </span>
                  <div className="info">
                    <h2>Mandager: 11:00 - 16:00</h2>
                    <h2>Tirsdager og Onsdager: 11:00 - 18:00</h2>
                    <h2>Torsdager: 11:00 - 17:00</h2>
                    <h2>Fredager: 11:00 - 18:00</h2>
                    <h2>Lørdager: etter avtale</h2>
                    <h2>Søndager: stengt</h2>
                  </div>
                </div>)
              }
            </React.Fragment>

          </div>
          <div className="contact__info_item item2">
            <span>
              Du finner oss her:
            </span>
            <div className="info">
              <h2>Torvet 5 2000 Lillestrøm</h2>
              <h2>+47 950 13 187</h2>
              <h2>ai@romerikebunadtilvirker.no</h2>
            </div>
          </div>
        </div>

        <div className="contact__info-map-form">
          {/* <ContactForm onSubmit={e => this.onSubmit(e)} {...this.state}/> */}
          <Map />

        </div>

        {
          <Modal isOpen={this.state.show} toggle={() => this.toggle()}>
            <ModalHeader toggle={() => this.toggle()}>Tusen takk!</ModalHeader>
            <ModalBody>
              Du vil få en bekreftelse snart på epost.
                </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => this.toggle()}>OK</Button>
            </ModalFooter>
          </Modal>
        }
      </div>
    );
  }
}

export default connect()(Contact);
