import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as HeaderImageDesktop } from '../assets/images/Romerike_logo_desktop.svg';
import { ReactComponent as HeaderImageMobile } from '../assets/images/Romerike_logo_mobile.svg';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean, screenWidth: number }> {
    public state = {
        isOpen: false,
        screenWidth: window.innerWidth
    };

    constructor(props: React.ReactPropTypes) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }

    onNavClick() {
        this.setState({ isOpen: false });
    }

    public render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow" dark>
                    <div className="navbar-container">
                        {
                            this.state.screenWidth > 767 ?
                                (
                                    <NavbarBrand tag={Link} to="/">
                                        <div className="navbar-brand-container">
                                            <HeaderImageDesktop />
                                        </div>
                                    </NavbarBrand>
                                ) :
                                <NavbarBrand tag={Link} to="/">
                                    <HeaderImageMobile />
                                </NavbarBrand>
                        }


                        <NavbarToggler onClick={this.toggle} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="navbar-navlink" to="/" onClick={() => this.onNavClick()}>Hjem</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="navbar-navlink" to="/bunader" onClick={() => this.onNavClick()}>Bunader</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="navbar-navlink" to="/about" onClick={() => this.onNavClick()}>Om Oss</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="navbar-navlink" to="/contact" onClick={() => this.onNavClick()}>Kontakt</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </div>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
