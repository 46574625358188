import * as React from 'react';
import { connect } from 'react-redux';
import HomeMenu from './components/HomeMenu';
import BunadList from './components/BunadList';
import InfoList from './components/InfoList';

const Home = () => (
  <div>
    <HomeMenu />
    <BunadList />
    <InfoList />
  </div>
);

export default connect()(Home);
