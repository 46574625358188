import * as React from 'react';
import {ReactComponent as LokaltIkon } from '../../../assets/images/Icon_lokalt.svg';
import {ReactComponent as ProveTimeIcon } from '../../../assets/images/Icon_provetime.svg';
import {ReactComponent as SaksIcon } from '../../../assets/images/Icon_saks.svg';
import {ReactComponent as SkreddersyddIcon } from '../../../assets/images/Icon_skreddersydd.svg';

class InfoList extends React.Component {
    infos = [
        {
            icon: <SaksIcon />,
            title: 'Erfaren bunadstilvirker',
            textInfo: `Lang erfaring og høy faglig kompetanse`
        },
        {
            icon: <LokaltIkon />,
            title: 'Lokalt i Romerike',
            textInfo: `Butikken ligger sentralt i Lillestrøm og er lett å komme seg til`
        },
        {
            icon: <SkreddersyddIcon />,
            title: 'Skreddersydd til deg',
            textInfo: `Vi følger norske tradisjoner og tilpasser bunaden til akkurat deg!`
        },
        {
            icon: <ProveTimeIcon />,
            title: 'Gratis prøvetime',
            textInfo: `Vi blir enige om dine ønsker og behov før du tar valget om å handle bunad fra oss`
        }
    ];

    constructor(props: React.ReactPropTypes) {
        super(props);
    }

    render() {
        return (
        <div className="info-list">
            {
                this.infos.map((item, index) => {
                    return (
                        <div className="info-list__item" key={index}>
                            {item.icon}
                            <h3>{item.title}</h3>
                            <span>{item.textInfo}</span>
                        </div>
                    );
                })
            }

        </div>)
    }
}

export default InfoList;