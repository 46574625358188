import * as React from 'react';

import coverImage from '../../../assets/images/28NYHBunad_06-07-773A2715-min.38bf9233.jpg';
import { Link } from 'react-router-dom';


class HomeMenu extends React.Component {
    bgImg: React.RefObject<HTMLImageElement>;

    constructor(props: React.ReactPropTypes) {
        super(props);
        this.bgImg = React.createRef();
    }

    componentDidMount() {
        const img = new Image();
        img.src = coverImage;
        img.onload = () => {
            const imgContainer = this.bgImg.current;
            if (imgContainer !== null) {
                imgContainer.style.backgroundImage = `linear-gradient(
                    rgba(0, 0, 0, 0.6),
                    rgba(0, 0, 0, 0.6)
                  ),url('${img.src}')`;
                imgContainer.classList.add("u-fade-in");
            }

        }
    }

    render() {
        return (
            <div className="home-menu">
                <div className="home-menu__container">
                    <div className="home-menu__image-wrapper">
                        <div className="home-menu__image" ref={this.bgImg} />
                    </div>

                    <header className="home-menu__info">
                        <h1>Skreddersydde bunader i Romerike!</h1>
                        <div className="home-menu__button-container">
                            <Link className={`btn btn--secondary`} to='/bunader' >Se bunader</Link>
                            <Link className={`btn btn--secondary`} to='/contact'>Bestill prøvetime</Link>
                        </div>
                    </header>
                </div>
            </div>
        );
    }
}

export default HomeMenu;
