import * as React from 'react'
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import * as APIKEYS from '../../../utils/apiKeys'

import marker from '../../../assets/images/location-pointer.png'
import { Link } from 'react-router-dom'

type MapProps = {
  center: {
    lat: number,
    lng: number
  }
  zoom: number,
}

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  background: url(${marker}) no-repeat center;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${(props: any) => (props.onClick ? 'pointer' : 'default')};
  &:hover {
    z-index: 1;
  }
`;

const Marker = (props: any) => 
<Wrapper
    {...props.onClick ? { onClick: props.onClick } : {}}>
    </Wrapper>;


const getMapBounds = (map: any, maps: any, place: any) => {
  const bounds = new maps.LatLngBounds();

  bounds.extend(new maps.LatLng(
    place.lat,
    place.lng,
  ));

  return bounds;
};

const bindResizeListener = (map: any, maps: any, bounds: any) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds);
    });
  });
};

const apiIsLoaded = (map: any, maps: any, place: any) => {
  const bounds = getMapBounds(map, maps, place);
  map.fitBounds(bounds);
  bindResizeListener(map, maps, bounds);
};


export default class Map extends React.Component<MapProps> {
  static defaultProps = {
    center: { lat: 59.9561877, lng: 11.0500389 },
    zoom: 18
  }

  constructor(props: MapProps) {
    super(props);
  }


  render() {
    return (
      <div className='google-map'>
        <GoogleMapReact
          bootstrapURLKeys={{ key: APIKEYS.MAPS_KEY }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >

          <Marker
            lat={this.props.center.lat}
            lng={this.props.center.lng}
            onClick={() => window.open(`https://maps.google.com/maps?q=${this.props.center.lat},${this.props.center.lng}`, "_blank")}
          />

        </GoogleMapReact>

      </div>
    )
  }
}